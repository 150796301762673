import styled from "styled-components"
import * as C from "../../styles/components"

import Img from "gatsby-image"

export const ProfileWrapper = styled.section`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--black_background_contrast);
  border-radius: 0.8rem;
  margin-bottom: 1.5rem;
`

export const ProfileAvatar = styled(Img)`
  border-radius: 50%;
  width: 200px;
  height: 200px;
  display: inline-block;
  margin: 1rem auto;
  object-fit: cover;
`
export const ProfileTitle = styled.h1`
  text-align: center;
  color: var(--white);
  font-size: 1.75rem;
  margin: 0.5rem 0;
`
export const ProfileText = styled.p`
  text-align: center;
  letter-spacing: 0.0069rem;
  color: var(--white);
`

export const ProfileButton = styled(C.InterButton)`
  margin: 0.5rem auto;
`
