import React from "react"

import { Home } from "@styled-icons/ionicons-outline/Home"
import { Email } from "@styled-icons/material-outlined/Email"
import { HandThumbsUp } from "@styled-icons/bootstrap/HandThumbsUp"
import { Search } from "@styled-icons/material-rounded/Search"
import { Googleads } from "@styled-icons/simple-icons/Googleads"
import { Book } from "@styled-icons/entypo/Book"
import { VipDiamond } from "@styled-icons/remix-line/VipDiamond"

import * as S from "./styled"

const NavbarLinks = () => {
  return (
    <S.HeaderMenu>
      <S.HeaderMenuItem
        to="/"
        cover
        direction="left"
        bg="#08070e"
        duration={0.6}
      >
        <S.HeaderIcon>
          <Home />
        </S.HeaderIcon>
        Home
      </S.HeaderMenuItem>
      <S.HeaderMenuItem
        to="/recomendacao"
        cover
        direction="left"
        bg="#08070e"
        duration={0.6}
      >
        <S.HeaderIcon>
          <HandThumbsUp />
        </S.HeaderIcon>
        Recomendo
      </S.HeaderMenuItem>
      <S.HeaderMenuItem
        to="/bonus"
        cover
        direction="left"
        bg="#08070e"
        duration={0.6}
      >
        <S.HeaderIcon>
          <VipDiamond />
        </S.HeaderIcon>
        Meus Bônus
      </S.HeaderMenuItem>
      <S.HeaderMenuItem
        to="/materiais"
        cover
        direction="left"
        bg="#08070e"
        duration={0.6}
      >
        <S.HeaderIcon>
          <Book />
        </S.HeaderIcon>
        Materiais Grátis
      </S.HeaderMenuItem>
      <S.HeaderMenuItemExterno
        href="https://bit.ly/ved-minicurso-gratis-menu"
        target="_blank"
      >
        <S.HeaderIcon>
          <Googleads />
        </S.HeaderIcon>
        Curso Grátis Google Ads
      </S.HeaderMenuItemExterno>
      <S.HeaderMenuItem
        to="/contato"
        cover
        direction="left"
        bg="#08070e"
        duration={0.6}
      >
        <S.HeaderIcon>
          <Email />
        </S.HeaderIcon>
        Contato
      </S.HeaderMenuItem>
      <S.HeaderMenuItem
        to="/pesquisar"
        cover
        direction="left"
        bg="#08070e"
        duration={0.6}
      >
        <S.HeaderIcon>
          <Search />
        </S.HeaderIcon>
      </S.HeaderMenuItem>
    </S.HeaderMenu>
  )
}

export default NavbarLinks
