import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as S from "./styled"

const Logo = () => {
  const { LogoImage } = useStaticQuery(graphql`
    query {
      LogoImage: file(relativePath: { eq: "logotipo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <S.HeaderLogo to="/" cover direction="left" bg="#08070e" duration={0.6}>
      <S.HeaderImg fluid={LogoImage.childImageSharp.fluid} />
    </S.HeaderLogo>
  )
}

export default Logo
