import React from "react"

import * as S from "./styled"
import * as C from "../../styles/components"

const TopAlert = () => {
  return (
    <S.TopAlertWrapper>
      <S.TopAlertText>
        Quer aprender a criar seu negócio na internet?
      </S.TopAlertText>
      <C.Button
        href="http://bit.ly/ved-curso-completo-top-site"
        target="_blank"
        rel="noopener noreferrer"
      >
        Saiba Mais
      </C.Button>
    </S.TopAlertWrapper>
  )
}

export default TopAlert
