import styled from "styled-components"
import media from "styled-media-query"

export const TopAlertWrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: center;
  background-color: var(--black_background);
  border-bottom: 10px solid var(--black_background_contrast);

  ${media.lessThan("large")`
    text-align: center;
    height: auto;
    padding:.5rem;
    flex-direction: column;
  `}
`

export const TopAlertText = styled.p`
  color: var(--white);
`
