import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const Button = styled.a`
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  color: var(--white);
  text-decoration: none;
  border: none;
  background-color: var(--dark_orange);
  margin-left: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.5;
  width: 8rem;
  text-align: center;
  height: 34px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background-color: var(--orange);
  }
`
export const InterButton = styled(AniLink)`
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  color: var(--white);
  text-decoration: none;
  border: none;
  background-color: var(--dark_orange);
  margin-left: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.5;
  width: 8rem;
  text-align: center;
  height: 34px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background-color: var(--orange);
  }
`
