import styled from "styled-components"
import media from "styled-media-query"

export const LayoutWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 2rem 5rem;

  ${media.lessThan("large")`
    flex-direction: column;
    padding: 0.5rem;
  `}
`
export const LayoutMain = styled.main`
  width: ${props => (props.visibleSideBar ? "70%" : "100%")};

  ${media.lessThan("large")`
    width: 100%;
  `}
`
export const LayoutAside = styled.aside`
  display: ${props => (props.visibleSideBar ? "flex" : "none")};
  width: 25%;
  flex-direction: column;

  ${media.lessThan("large")`
    width:100%;
    margin-top: 1rem;
  `}
`
