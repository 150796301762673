import React from "react"

import { Rocket } from "@styled-icons/ionicons-outline/Rocket"
import { LaptopMac } from "@styled-icons/material-twotone/LaptopMac"
import { Puzzle } from "@styled-icons/heroicons-outline/Puzzle"
import { Server } from "@styled-icons/feather/Server"
import { Sword } from "@styled-icons/remix-fill/Sword"
import { Googleads } from "@styled-icons/simple-icons/Googleads"

import * as S from "./styled"

const BannerLinks = () => {
  return (
    <S.BannerWrapper>
      <S.Banner
        href="http://bit.ly/ved-curso-completo-aside"
        target="_blank"
        rel="noopener noreferrer"
      >
        <S.Icon>
          <LaptopMac />
        </S.Icon>
        Fórmula Negócio Online
      </S.Banner>
      <S.Banner
        href="http://bit.ly/ved-viking-aside"
        target="_blank"
        rel="noopener noreferrer"
      >
        <S.Icon>
          <Sword />
        </S.Icon>
        Afiliado Viking
      </S.Banner>
      <S.Banner
        href="http://bit.ly/ved-gpa-aside"
        target="_blank"
        rel="noopener noreferrer"
      >
        <S.Icon>
          <Googleads />
        </S.Icon>
        GPA - Domínio Estratégico
      </S.Banner>
      <S.Banner
        href="https://bit.ly/ved-hospedagem-teste-gratis"
        target="_blank"
        rel="noopener noreferrer"
      >
        <S.Icon>
          <Server />
        </S.Icon>
        Hospedagem com 7 dias grátis
      </S.Banner>
      <S.Banner
        href="http://bit.ly/ved-tema-avenger-aside"
        target="_blank"
        rel="noopener noreferrer"
      >
        <S.Icon>
          <Rocket />
        </S.Icon>
        Tema para Wordpress
      </S.Banner>
      <S.Banner
        href="http://bit.ly/ved-rgpd-aside"
        target="_blank"
        rel="noopener noreferrer"
      >
        <S.Icon>
          <Puzzle />
        </S.Icon>
        Plugin RGPD Pro
      </S.Banner>
    </S.BannerWrapper>
  )
}

export default BannerLinks
