import styled from "styled-components"

export const BannerWrapper = styled.section`
  display: flex;
  flex-direction: column;
`

export const Banner = styled.a`
  cursor: pointer;
  margin: 1rem 0;
  padding: 2.5rem 2rem;
  text-decoration: none;
  font-weight: 300;
  font-size: 1.25rem;
  color: var(--white);
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  width: 100%;
  height: 7rem;
  border-left: ${props =>
    props.border ? props.border : "15px solid var(--orange)"};
  border-radius: 0.5rem;
  justify-content: start;
  background-color: ${props =>
    props.bgColor ? props.bgColor : "var(--dark_orange)"};
  transition: box-shadow 1.75s;

  &:hover {
    box-shadow: ${props =>
      props.shadow ? props.shadow : "inset -30rem 0 0 0 var(--orange)"};
  }
`
export const Icon = styled.svg`
  width: 3rem;
  margin-right: 1rem;
`
