import React from "react"
import PropTypes from "prop-types"

import TopAlert from "../TopAlert"
import Header from "../Header"
import Profile from "../Profile"
import BannerLinks from "../BannerLinks"
import SocialIconsAside from "../SocialIconsAside"
import Footer from "../Footer"

import GlobalStyles from "../../styles/global"

import * as S from "./styled"

const Layout = ({ children, visibleSideBar }) => {
  return (
    <>
      <GlobalStyles />
      <TopAlert />
      <Header />
      <S.LayoutWrapper>
        <S.LayoutMain visibleSideBar={visibleSideBar}>{children}</S.LayoutMain>
        <S.LayoutAside visibleSideBar={visibleSideBar}>
          <Profile />
          <BannerLinks />
          <SocialIconsAside />
        </S.LayoutAside>
      </S.LayoutWrapper>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
