import styled from "styled-components"
import media from "styled-media-query"

import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const HeaderToggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 0 10vw;

  ${media.lessThan("large")`
    display: flex;
    padding: 0 2.5vw;
  `}
`

export const HeaderNavBox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  ${media.lessThan("large")`
   flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    padding: 1.5rem;
    background-color: var(--black_background);
    transition: all 0.3s ease-in;
    top: 0;
    z-index:1200;
    left: ${props => (props.open ? "-100%" : "0")};
    `}
`

export const HeaderHamburguer = styled.div`
  background-color: var(--white);
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};
  display: none;

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: var(--white);
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }

  ${media.lessThan("large")`
    display: block;
  `}
`

export const HeaderWrapper = styled.header`
  background-color: var(--black_background);
  width: 100%;
  height: 150px;
  padding: 2rem 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3rem solid var(--black_background_contrast);

  ${media.lessThan("large")`
    height: 70px;
    padding: 1rem;
    position: fixed;
    bottom: 0;
    margin: 0;
    border-top: 5px solid var(--black_background_contrast);
    border-bottom: none;
    z-index:1200;
  `}
`
export const HeaderLogo = styled(AniLink)``

export const HeaderImg = styled(Img)`
  width: 80px;
`

export const HeaderMenu = styled.nav`
  display: flex;

  ${media.lessThan("large")`
    width: 100%;
    height: 100vh;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column-reverse;
  `}
`

export const HeaderMenuItem = styled(AniLink)`
  padding: 0.5rem 0.5rem;
  margin: 0 0.5rem;
  cursor: pointer;
  border-radius: 0.25rem;
  color: var(--white);
  text-decoration: none;
  font-size: 1.2rem;
  transition: background 0.5s;
  display: flex;
  align-items: center;
  font-weight: 300;

  &:hover {
    text-decoration: underline;
    color: var(--dark_orange);
  }

  ${media.lessThan("large")`
    margin:.5rem 0;
  `}
`

export const HeaderMenuItemExterno = styled.a`
  padding: 0.5rem 0.5rem;
  margin: 0 0.5rem;
  cursor: pointer;
  border-radius: 0.25rem;
  color: var(--white);
  text-decoration: none;
  font-size: 1.2rem;
  transition: background 0.5s;
  display: flex;
  align-items: center;
  font-weight: 300;

  &:hover {
    text-decoration: underline;
    color: var(--dark_orange);
  }
`

export const HeaderIcon = styled.svg`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
`
