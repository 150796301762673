import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as S from "./styled"

const Profile = () => {
  const { imageProfile } = useStaticQuery(graphql`
    query {
      imageProfile: file(
        relativePath: { eq: "profile/voce-empreendedor-digital-profile.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <S.ProfileWrapper>
      <S.ProfileAvatar
        fluid={imageProfile.childImageSharp.fluid}
        alt="Foto de Willian D Santos - Empreendedor Digital"
      />
      <S.ProfileTitle>Willian D. Santos</S.ProfileTitle>
      <S.ProfileText>
        Empreendedor Digital. Trabalho com a internet em qualquer lugar que eu
        quiser com liberdade e bons rendimentos. Quero ajudar você a construir o
        mesmo.
      </S.ProfileText>
      <S.ProfileButton
        to="/sobre"
        cover
        direction="left"
        bg="#08070e"
        duration={0.6}
      >
        Sobre mim
      </S.ProfileButton>
    </S.ProfileWrapper>
  )
}

export default Profile
